<template>
  <div>
    <div class="card" v-if="loading">
      <div class="card-body">
        <div class="text-center text-info my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </div>
    </div>
    <div class="card" v-else-if="error">
      <div class="card-body">
        <div class="text-center text-danger my-2">
          <span class="mb-2 d-block">Erro ao carregar dados!</span>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="store == null">
      <div class="col-12">
        <div class="card">
          <div class="card-body py-5 text-center">
            <h5 class="mb-3">Nenhum item encontrado!</h5>
            <img width="250" src="@/assets/images/not-data.svg" />
          </div>
        </div>
      </div>
    </div>
    <b-card-text v-else>
      <div class="card">
        <div class="card-body linkmenus">
          <h4 class="card-title">Dados Gerais</h4>
          <hr />
          <b-tabs pills nav-class="p-0" nav-wrapper-class="w-100">
            <b-tab active>
              <template v-slot:title>
                <p class="font-weight-bold p-0 m-0 mx-3 my-2">Imagens</p>
              </template>
              <div class="row">
                <div class="col-12 mb-4">
                  <label>Imagens</label>
                  <label class="w-100 cursor-pointer">
                    <div class="uploadimg w-100 border p-5 text-center">
                      <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                      <h6 class="text-muted">clique para enviar imagem</h6>
                    </div>
                    <input
                      type="file"
                      @change="onFileChange"
                      name="image[]"
                      multiple
                      class="d-none"
                    />
                  </label>
                </div>

                <div class="col-12" v-if="imgs.length > 0">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Imagem</th>
                          <th scope="col" class="text-center">Logo Escura</th>
                          <th scope="col" class="text-center">Logo Clara</th>
                          <th scope="col" class="text-center">Favicon</th>
                          <th scope="col" class="text-center">Ação</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="text-center align-middle"
                          v-for="(i, index) in imgs"
                          :key="i.id"
                        >
                          <td class="text-left align-middle">
                            <b-img
                              width="80"
                              :src="i.url"
                              rounded
                              alt="Rounded image"
                            ></b-img>
                          </td>
                          <td class="align-middle">
                            <input
                              class="form-check-input position-static"
                              type="radio"
                              v-model="i.logo_dark"
                              value="1"
                              @click="altCheckEditar(index, 'logo_dark')"
                            />
                          </td>
                          <td class="align-middle">
                            <input
                              class="form-check-input position-static"
                              type="radio"
                              @click="altCheckEditar(index, 'logo_clear')"
                              v-model="i.logo_clear"
                              value="1"
                            />
                          </td>
                          <td class="align-middle">
                            <input
                              class="form-check-input position-static"
                              type="radio"
                              @click="altCheckEditar(index, 'favicon')"
                              v-model="i.favicon"
                              value="1"
                            />
                          </td>
                          <td class="align-middle">
                            <b-button
                              variant="danger"
                              @click.prevent="removerImagem(i)"
                            >
                              <i
                                class="bx bx-trash font-size-16 align-middle"
                              ></i>
                            </b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-12 my-3 text-right">
                  <b-button variant="success" @click.prevent="saveData()">
                    <i class="bx bx-save font-size-16 align-middle mr-2"></i>
                    Salvar
                  </b-button>
                </div>
              </div>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <p class="font-weight-bold p-0 m-0 mx-3 my-2">SEO</p>
              </template>
              <div class="row">
              <div class="col-12">
                  <label>
                    Nome da Loja
                  </label>
                  <b-form-group>
                    <b-form-input
                      placeholder="Nome..."
                      for="text"
                      v-model="store.name"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-12">
                  <label>
                    Título
                  </label>
                  <b-form-group>
                    <b-form-input
                      placeholder="Título..."
                      for="text"
                      v-model="store.meta_title"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-12">
                  <label>
                    Descrição
                  </label>
                  <b-form-textarea
                    id="textarea"
                    placeholder="Descrição..."
                    rows="6"
                    max-rows="6"
                    v-model="store.meta_description"
                  ></b-form-textarea>
                </div>
                <div class="col-12 my-3 text-right">
                  <b-button variant="success" @click.prevent="saveData()">
                    <i class="bx bx-save font-size-16 align-middle mr-2"></i>
                    Salvar
                  </b-button>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </b-card-text>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  components: {},
  data() {
    return {
      loading: true,
      error: false,
      store: null,
      imgs: [],
    }
  },
  mounted() {
    this.findStore()
  },
  methods: {
    ...mapActions('appearance', [
      'ActionAppearanceFind',
      'ActionAppearanceUpdate',
      'ActionAppearanceDeleteImage',
      'ActionAppearanceUpdateImage',
    ]),
    async findStore() {
      try {
        await this.ActionAppearanceFind().then((res) => {
          this.store = res.data.data
          this.loading = false
          this.error = false
          res.data.data.image.forEach((element) => {
            var g = {
              file: null,
              url: element.image300,
              capa: element.capa,
              logo_dark: element.logo_dark,
              logo_clear: element.logo_clear,
              favicon: element.favicon,
              id: element.id,
            }
            this.imgs.push(g)
          })
        })
      } catch (e) {
        this.loading = false
        this.error = true
        this.store = null
      }
    },
    async saveData(){
      try {
        let data = new FormData()
        if (this.imgs.length > 0) {
          this.imgs.forEach((element) => {
            if(element.file != null){
                 data.append('image[]', element.file)
                 data.append('dark[]', element.logo_dark)
                 data.append('clear[]', element.logo_clear)
                 data.append('favicon[]', element.favicon)
            }
          })
        }
        data.append('id', this.store.id)
        data.append('meta_title', this.store.meta_title)
        data.append('meta_description', this.store.meta_description)
        data.append('name', this.store.name)
        this.$swal({
          title: 'Aguarde!',
          text: 'Salvando Dados',
          icon: 'info',
          showConfirmButton: false,
        })
        await this.ActionAppearanceUpdate(data)
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        })
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    onFileChange(e) {
      e.target.files.forEach((element) => {
        var g = {
          file: element,
          url: URL.createObjectURL(element),
          capa: 0,
          logo_dark: 0,
          logo_clear: 0,
          favicon: 0,
          id: null,
        }
        this.imgs.push(g)
        var favicon = 0
        var logo_dark = 0
        var logo_clear = 0
        this.imgs.forEach((element) => {
          if (element.favicon == 1) {
            favicon = 1
          }
          if (element.logo_dark == 1) {
            logo_dark = 1
          }
          if (element.logo_clear == 1) {
            logo_clear = 1
          }
        })
        if (favicon <= 0) {
          this.imgs[0].favicon = 1
        }
        if (logo_dark <= 0) {
          this.imgs[0].logo_dark = 1
        }
        if (logo_clear <= 0) {
          this.imgs[0].logo_clear = 1
        }
      })
    },
    removerImagem(data) {
      if (data.id != null) {
        this.ActionAppearanceDeleteImage(data.id)
      }
      this.imgs.splice(this.imgs.indexOf(data), 1)

      if (this.imgs.length > 0) {
        this.imgs[0].logo_dark = data.logo_dark == 1 ? 1 : this.imgs[0].logo_dark
        this.imgs[0].logo_clear = data.logo_clear == 1 ? 1 : this.imgs[0].logo_clear
        this.imgs[0].favicon = data.favicon == 1 ? 1 : this.imgs[0].favicon
        if (this.imgs[0].id != null) {
          var fg = {
            id: this.imgs[0].id,
            data: this.imgs[0],
          }
          this.ActionAppearanceUpdateImage(fg)
        }
      }
    },
    altCheckEditar(i, tipo) {
      var t = []
      this.imgs.forEach((element) => {
        if (tipo == 'logo_dark') {
          element.logo_dark = 0
        }
        if (tipo == 'logo_clear') {
          element.logo_clear = 0
        }
        if (tipo == 'favicon') {
          element.favicon = 0
        }
        t.push(element)
        if (element.id != null) {
          var f = {
            id: element.id,
            data: element,
          }
          this.ActionAppearanceUpdateImage(f)
        }
      })
      this.imgs = t
      if (tipo == 'logo_dark') {
        this.imgs[i].logo_dark = 1
      }
      if (tipo == 'logo_clear') {
        this.imgs[i].logo_clear = 1
      }
      if (tipo == 'favicon') {
        this.imgs[i].favicon = 1
      }

      if (this.imgs[i].id != null) {
        var fg = {
          id: this.imgs[i].id,
          data: this.imgs[i],
        }
        this.ActionAppearanceUpdateImage(fg)
      }
    },
  },
}
</script>
